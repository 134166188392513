export const columnsDataColumns = [
    {
      Header: "NAME",
      accessor: "contact.name",
    },
    {
      Header: "EMAIL",
      accessor: "contact.email",
    },
    {
      Header: "TELEPHONE",
      accessor: "contact.phone",
    },
    {
      Header: "DATE",
      accessor: "contact.date",
    },
  ];
  